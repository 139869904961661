/**
 * @desc 全局仓库
 * @author zhangxue
 * @date 2022-3-31
 */
import { createStore } from 'vuex'

export default createStore({
	state () {
		return {
			count: 0
		}
	},
	mutations: {
		increment () {
		}
	}
})