import axios from 'axios'
import qs from 'qs'
import {wxAuthorize} from '@/utils/tool'
import { Toast, Notify } from 'vant';

//接口返回失败提示信息
const errorMsg = '服务器开小差了，请稍后再试！';
//需要特殊处理的code-页面功能中自定义反馈效果
const specialCode = [-3, -21, -22, -23]
//  让请求在浏览器中允许跨域携带cookie
// axios.defaults.withCredentials = true;
// axios.defaults.credentials = true;

//线上/线上环境请求域名区分
const baseUrl = process.env.NODE_ENV === 'production' ? window.ipConfig.baseUrl : '';

// 使用自定义配置新建一个 axios 实例
const service = axios.create({
    // 基础的请求地址
    baseURL: baseUrl,
    // 设置超时时间 5s
    timeout: 5000
});

// 请求拦截器，例如请求的时候在头部加上请求的token
service.interceptors.request.use(
    config => {
        if (config.method == "post") {
            config.headers = {
                "content-type": "application/x-www-form-urlencoded"
            }
            config.data = qs.stringify(config.data);
        }
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.WxAuthorization = token
            // config.headers.auth_token = token
        }
        return config;
    },
    error => {
        return Promise.reject(error)
    }
)


// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，我分开写了一个比较直观
service.interceptors.response.use(
    response => {
        console.log(response)
        Toast.clear();
        if (response.status === 200 && response.data.code == 200) {
            return Promise.resolve(response.data);
        } else if(response.status === 203) {
            //token失效
            localStorage.removeItem('token');
            localStorage.removeItem('openId');
            wxAuthorize();
        } else if(response.status === 200 && response.data.code === -7) {
            Notify({ type: 'warning', message: '5秒内不能重复操作' });
            return Promise.reject();
        } else {
            const msg = response.data ? response.data.msg : ''
            const code =  response.data ? response.data.code : ''
            if (!msg) {
              Notify({ type: 'warning', message: errorMsg });
            } else if(specialCode.indexOf(code) == -1) {
              Toast.fail(msg);
            }
            return Promise.reject(response.data);
        }
    },
    // 服务器状态码不是200的情况,这些自定义（需要与后台商量返回）
    error => {
        Toast.clear();
        if(!error.response) {
            // Toast.fail('服务器开小差了，请稍后再试！');
            Notify({ type: 'warning', message: errorMsg });
            return Promise.reject();
        } else {
          const msg = error.response.data ? error.response.data.msg : ''
          if (!msg) {
            Notify({ type: 'warning', message: errorMsg });
          } else {
            Toast.fail(msg);
          }
          return Promise.reject(error.response.data || error.response, error);
        }
    }
);

export default service;