import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store/index'
import routerMixin from '@/mixins/router'
import vueWechatTitle from 'vue-wechat-title' //修改title
import moment from 'moment';
//引入微信SDK
import wx from "weixin-sdk-js";
//微信用户授权
import {wxAuthorize} from '@/utils/tool';
//弹框
import popupDe from './components/popupDe/popupDe.vue'

//TODO 测试用，正式环境需要删除
// localStorage.setItem('openId', 'o3jWB6qMBDR_h4547gwZJ_aNrDGg');
// localStorage.setItem('token', 'c99023-d24f-46a8-a59a-8997c7a7eea6');

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vueWechatTitle)
app.mixin(routerMixin);
app.mount('#app');
app.component('de-popup', popupDe);

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否注册
router.beforeEach((to,from,next) => {
	if(to.path === '/registerPage'){
		next();
	}else{
		let token = window.localStorage.token;
		if(token === 'null' || token === '' || token === undefined){
			wxAuthorize(next);
		}else{
			next();
		}
	}
});


//微信SDK
app.config.globalProperties.$wx = wx;
app.config.globalProperties.$moment = moment;

//按需引入vant组件
import {
	Button, Form, Field, Toast, Dialog,
	Cell, CellGroup, Checkbox, Col, Row,
	PullRefresh, List, RadioGroup, Radio,
	Empty, Step, Steps, Icon, DatetimePicker,
	Popup, Notify
} from 'vant';
app.use(Button);
app.use(Form);
app.use(Field);
app.use(Cell);
app.use(CellGroup);
// 将所有 loading Toast 设置为背景不可点击
Toast.setDefaultOptions('loading', {
	forbidClick: true,
	// overlay: true,
	duration: 0
});
app.use(Toast);
app.use(Dialog);
app.use(Checkbox);
app.use(Col);
app.use(Row);
app.use(PullRefresh);
app.use(List);
app.use(Radio);
app.use(RadioGroup);
app.use(Empty);
app.use(Step);
app.use(Steps);
app.use(Icon);
app.use(DatetimePicker);
app.use(Popup);
app.use(Notify);

