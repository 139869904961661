/**
 * @desc 基础方法
 */
//查询页面地址参数
export const getQueryString = (name) => {
	const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	const r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return unescape(r[2]);
	} else {
		return null;
	}
}

//用户微信授权
// import { appId } from '@/utils/api';
import { getWxUser } from '@/request/api';
export const wxAuthorize = (callback) => {
	const code = getQueryString('code');
	const token = localStorage.getItem('token');
	const openId = localStorage.getItem('openId');
	const scope = 'snsapi_base';
	const pathName = window.location.pathname;
	//没有授权，重定向到注册页面
	let redirectUri = '';
	const stateCon = '';
	if(pathName.indexOf('verificationResultPage') > -1) {
		//防伪验证不需要注册
		if(callback) {
			callback();
		}
		return true;
	} else if(!code && !token && !openId) {
		//没有登录和code数据，通过授权后，进入页面
		redirectUri = encodeURIComponent(window.location.href);
		window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${window.ipConfig.appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${stateCon}#wechat_redirect`;
	} else if(!token && !openId) {
		getWxUser({
			code: code
		}).then(res => {
			const data = res.data;
			const token = data.WxAuthorization || data.auth_token;
			if(token) {
				localStorage.setItem('token', token);
			}
			if(data.openid) {
				localStorage.setItem('openId', data.openid);
			}
			if(callback) {
				callback();
			}
			if(token) {
				//获取用户个人信息
				getCurrentUserInfo();
			} else {
				//不是注册和用户中心页面，跳转到注册页面
				toRegisterPage();
			}
		}).catch(res => {
			console.log(res);
		})
	} else if(!token) {
		console.log(2)
		//不是注册和用户中心页面，跳转到注册页面
		toRegisterPage(callback);
	} else {
		console.log(2)
		getCurrentUserInfo(callback);
	}
}

import { Toast } from 'vant';
import router from '@/router/index';
//不是注册和用户中心页面，跳转到注册页面
export const toRegisterPage = (callback) => {
	const pathName = window.location.pathname;
	console.log(pathName);
	if(pathName.indexOf('registerPage') == -1 && pathName.indexOf('userHomePage') == -1) {
		console.log(2.1)
		Toast.fail('请先进行注册');
		console.log(2.2)
		console.log(router)
		router.push({
			name: 'registerPage'
		});
		console.log(2.3)
	} else {
		if(callback) {
			callback();
		}
	}
}

//微信SKD配置
//_this vue实例对象
//jsApiList 需要调用的JS接口列表，凡是要调用的接口都需要传进来
import { getWxSign } from '@/request/api';
export const wxConfig = async (_this, jsApiList) => {
	let query = {
        url: (window.location.href).split('#')[0]
	};
	// alert('url = ' + query.url);
	Toast.loading({message: '加载中...'})
	await getWxSign(query).then(res => {
		const data = res.data;
		Toast.clear();
		console.log(jsApiList);
		_this.$wx.config({
			beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			appId: data.appId, // 必填，企业微信的corpID
			timestamp: data.timestamp, // 必填，生成签名的时间戳
			nonceStr: data.nonceStr, // 必填，生成签名的随机串
			signature: data.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
			jsApiList: jsApiList || [] // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
		});
	}).catch(res => {
		Toast.clear();
		Toast.fail(res.msg || res);
		console.log(res);
	});
	return false;
}

//获取用户信息
import { getUserInfo  } from '@/request/api';
export const getCurrentUserInfo = (callback) => {
	// const userInfo = JSON.parse(localStorage.getItem('userInfo'));
	// if(userInfo) {
	// 	console.log(23)
	// 	if(callback) {
	// 		console.log(45)
	// 		callback(userInfo || {});
	// 	}
	// } else {
	const infoToast =  Toast.loading();
		const query = {};
		getUserInfo(query)
			.then(res => {
				infoToast.clear();
				const userInfo = JSON.stringify(res.data);
				localStorage.setItem('userInfo', userInfo);
				if(callback) {
					callback(res.data || {});
				}
			})
			.catch(res => {
				infoToast.clear();
				localStorage.removeItem('userInfo');
				const url = res.data || '';
				if(url) {
					location.href = url;
				}
			});
	// }
};
