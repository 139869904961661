import {createRouter, createWebHistory} from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/userHomePage',
      name: 'userHomePage',
      component: ()=>import('@/module/userHomePage/userHomePage.vue'),
      meta: {
        title: '注册与注销'
      }
    },
    {
      path: '/registerPage',
      name: 'registerPage',
      component: ()=>import('@/module/registerPage/registerPage.vue'),
      meta: {
        title: '注册'
      }
    },
    {
      path: '/logoutPage',
      name: 'logoutPage',
      component: ()=>import('@/module/logoutPage/logoutPage.vue'),
      meta: {
        title: '注销'
      }
    },
    {
      path: '/setChannelPage',
      name: 'setChannelPage',
      component: ()=>import('@/module/setChannelPage/setChannelPage.vue'),
      meta: {
        title: '推送渠道设置'
      }
    },
    {
      path: '/coupleBackPage',
      name: 'coupleBackPage',
      component: ()=>import('@/module/coupleBackPage/coupleBackPage.vue'),
      meta: {
        title: '反馈'
      }
    },
    {
      path: '/verificationResultPage',
      name: 'verificationResultPage',
      component: ()=>import('@/module/verificationResultPage/verificationResultPage.vue'),
      meta: {
        title: '防伪验证'
      }
    },
    {
      path: '/orderList',
      name: 'orderList',
      component: ()=>import('@/module/orderList/orderList.vue'),
      meta: {
        title: '订单查询'
      }
    },
    {
      path: '/evaluatePage',
      name: 'evaluatePage',
      component: ()=>import('@/module/evaluatePage/evaluatePage.vue'),
      meta: {
        title: '回访评价'
      }
    }
  ]
})
