/**
 * @desc 路由跳转相关-已全局注册
 * @author zhangxue
 * @date 2022-3-31
 */

export default {
	methods: {
		//页面跳转
		toNextPageByName(herfName) {
			if(!herfName) {
				return false;
			}

			this.$router.push({
				name: herfName
			});
		}
	}
}