<!--提示框-->
<template>
    <van-popup
        :show="open" round
        :overlay-class="$style.popupOverlay"
        :class="$style.popupBox">
        <div :class="$style.imgBox">
            <img :src="imgUrl"/>
        </div>
        <div :class="$style.msgTitle">{{ title }}</div>
        <div :class="$style.msgText">{{ msg }}</div>
        <div :class="$style.btnBox">
            <van-button :class="$style.btnInfo" type="primary" @click="closePopup">{{ btnMsg }}</van-button>
        </div>
    </van-popup>
</template>
<script>
import { computed } from "vue"
const msgImg = require('@/assets/image/msgImgBase.png')

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        imgUrl: {
            type: String,
            default: msgImg
        },
        title: {
            type: String,
            default: '温馨提示'
        },
        msg: {
            type: String,
            default: ''
        },
        btnMsg: {
            type: String,
            default: '知道了'
        }
    },
    emits: ["update:show"],
    setup(props, {emit}) {
        let open = computed({
            get: () => props.show,
            set: (val) => {
                emit('update:show', val)
            }
        })

        const closePopup = () => {
            emit('update:show', false)
            emit('closeCallback')
        }

        return {
            open,
            closePopup
        }
    },
    methods: {

    }
}
</script>
<style module lang="scss">
    *{
        box-sizing: border-box;
    }
    .popupBox{
        width: 80%;
        top: 44%;
        padding: 38px 28px 33px;
        box-sizing: border-box;
    }
    .popupOverlay{
        background: rgba(0, 0, 0, 0.4);
    }
    .imgBox{
        width: 100%;
        text-align: center;
        img{
            width: 100%;
        }
    }
    @mixin text{
        width: 100%;
        text-align: center;
    }
    .msgTitle{
        @include text;
        font-size: 15px;
        color: #333333;
        margin: 17px 0 10px;
    }
    .msgText{
        @include text;
        font-size: 12px;
        color: #626262;
        margin-bottom: 22px;
    }
    .btnBox{
        width: 100%;
        padding: 0 56px;
        box-sizing: border-box;
    }
    .btnInfo{
        height: 40px;
        width: 100%;
        background: #156CE5;
        border-radius: 7px;
        font-size: 16px;
    }
</style>