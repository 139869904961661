// 刚刚封装的axios
import request from './axios';

const preCustomerUrl = '/wx/customer';
const preBusinessUrl = '/wx/business';

export const getApi = (url, query) => {
	return request({
		url: url,
		method: 'get',
		params: query
	})
}

export const postApi = (url, query) => {
	return request({
		url: url,
		method: 'post',
		data: query
	})
}

export const deleteApi = (url) => {
	return request({
		url: url,
		method: 'delete'
	})
}

// 前端向服务器获取auth_token和openid
export const getWxCode = (query) => {
	return getApi(`/wx/getWxCode`, query);
}

//前端向服务器获取auth_token或openid,需传入微信code
export const getWxUser = (query) => {
	return getApi(`/wx/getWxUser`, query);
}

//前端向服务器获取微信扫一扫功能签名
export const getWxSign = (query) => {
	return getApi(`/wx/getWxSign`, query);
}

//微信客户端注册发送验证码
export const sendCode = (query) => {
	return getApi(`${preCustomerUrl}/sendCode`, query);
}

// 微信客户端注册用户
export const add = (query) => {
	return postApi(`${preCustomerUrl}/add`, query);
}

//微信客户端个人信息获取
export const getUserInfo = (query) => {
	return getApi(`${preCustomerUrl}/info`, query);
}

//微信客户端注销用户
export const logout = (query) => {
	return postApi(`${preCustomerUrl}/logout`, query);
}

//微信客户端推送微信消息设置
export const pushSetting = (query) => {
	return postApi(`${preCustomerUrl}/pushSetting`, query);
}

//反馈
export const advice = (query) => {
	return postApi(`${preBusinessUrl}/advice`, query);
}

//项目评价
export const evaluate = (query) => {
	return postApi(`${preBusinessUrl}/evaluate`, query);
}

//查询项目评价内容
export const evaluateContent = (query) => {
	return getApi(`${preBusinessUrl}/evaluateContent`, query);
}

//项目系统查询
export const getBusinessPage = (query) => {
	return getApi(`${preBusinessUrl}/page`, query);
}

//验证报告真伪
export const validateReport = (query) => {
	return getApi(`${preBusinessUrl}/validateReport`, query);
}


