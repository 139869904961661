<template>
    <div id="app">
        <router-view v-wechat-title='$route.meta.title'/>
    </div>
</template>

<script>
export default {
    name: 'App'
}
</script>

<style>
html, body {
    height: 100%;
    width: 100%;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background: #F9F9F9 ;
    height: 100%;
    width: 100%;
}
</style>
